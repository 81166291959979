<template>
  <div>
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="onClickLeft"
      :safe-area-inset-top="true"
      :border="false"
      :fixed="true"
      :placeholder="true"
    />
    <img src="@/static/images/sincere_big@2x.jpg" alt="" srcset="" class="casesImg" />
  </div>
</template>

<script>
export default {
  name: "SincereSellingCases",
  data() {
    return {
      title: "诚心卖服务示例",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.casesImg {
  width: 100%;
}
::v-deep .van-nav-bar .van-icon {
  color: black;
  font-size: 16px;
}
</style>
